import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function MyFavoriteMistake({ data }) {
  return (
    <Layout>
      <Helmet title="My Favorite Mistake | Sheryl Crow" />

      <h1>My Favorite Mistake</h1>

      <h2>Sheryl Crow</h2>

      <h4>Key: G#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>I woke up and called this morning</p>
          <p>The tone of your voice was a warning</p>
          <p>That you don't care for me anymore</p>
        </Verse>
        <Verse>
          <p>I made up the bed we sleep in</p>
          <p>I looked at the clock when you creep in</p>
          <p>It's 6 AM and I'm alone</p>
        </Verse>
        <Chorus>
          <p>Did you know when you go it's the perfect ending</p>
          <p>To the bad day I was just beginning</p>
          <p>When you go, all I know is you're my favorite mistake</p>
        </Chorus>
        <Verse>
          <p>Your friends are sorry for me</p>
          <p>They watch you pretend to adore me</p>
          <p>But I am no fool to this game</p>
        </Verse>
        <Verse>
          <p>Now here comes your secret lover</p>
          <p>She'll be unlike any other</p>
          <p>Until your guilt goes up in flames</p>
        </Verse>
        <Chorus>
          <p>Did you know when you go it's the perfect ending</p>
          <p>To the bad day I'd gotten used to spending</p>
          <p>When you go, all I know is you're my favorite mistake</p>
          <p>You're my favorite mistake</p>
        </Chorus>
        <Bridge>
          <p>Well maybe nothing lasts forever</p>
          <p>Even when you stay together</p>
          <p>I don't need forever after, but</p>
          <p>it's your laughter won't let me go</p>
          <p>So I'm holding on this way</p>
        </Bridge>
        <Bridge>
          <p>Did you know could you tell you were the only one</p>
          <p>That I ever loved?</p>
          <p>Now everything's so wrong</p>
          <p>Did you see me walking by, did it ever make you cry?</p>
        </Bridge>
        <Chorus>
          <p>Now you're my favorite mistake</p>
          <p>Yeah you're my favorite mistake</p>
          <p>You're my favorite mistake</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "my-favorite-mistake.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
